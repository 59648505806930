.performersGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 110px);
    gap: 5px;
    padding: 5px;
    justify-content: center;
  }
  
  @media (max-width: 560px) {
    .performersGrid {
      grid-template-columns: 100%;
    }
  }