.movieCard {
    display: flex;
    align-items: center; 
    aspect-ratio: 800 / 540;
    list-style: none;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgb(179, 179, 179);
  }
  
  .movieCard:hover {
    opacity: 0.8;
  }
   
  .movieImage {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
  
  @media (max-width: 560px) {
    .movieImage {
      width: 100%;
      height: auto;
    }
  }