.grid {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0;
    gap: 10px;
    font-size: 1rem;
  }
  
  .gridItem.video {
    width: 100%;
  }
    
  .gridItem.details {
    font-size: 1.0rem;
    padding: 0 5px;
  }
  
  .gridItem.performers {
    padding: 0 5px;
  }
  
  .avatarContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
    gap: 5px;
  }
  
  
  @media screen and (min-width: 550px) {
    .grid {
      display: grid;
      grid-template-columns: 2fr 3fr;
      grid-template-rows: auto auto;
      margin: 0 10px;
      gap: 10px;
      font-size: 1rem;
    }
  
    .gridItem.video {
      min-width: 350px;
    }
      
  .gridItem.performers {  
      grid-column: 1 / span 2;
    }   
  }
