.container {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.em;
    max-width: 100px;
    overflow: hidden;   
    gap: 2px; 
cursor: pointer;
}

.imageContainer {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
}