

#my-video {
    box-shadow: 0 0 20px 0px black;
    min-width:80%;
    min-height:80%;
  }
  
  .contentMenu {
    position: relative;
    width:4em;
    justify-content: center;
    align-content: center;
    .icon {
      display: flex;
      justify-items: center;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 1.2em;
      color:white;
      &:hover {
        cursor:pointer;
        color:gray;
        
          & + .menu{
         
        transform:translate(-50%,-30px);
          visibility:visible;
          opacity:1;
        } 
        
      }
    }
  
    .videosContentMenu { 
      width:5em;
    }
    
    .menu {
      position:absolute;
      bottom:20px;
      left:50%;
  
      transition:all 200ms;
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      width: 100px;
      
      transform:translate(-50%,-20px);
      visibility:hidden;
      opacity:0;
      &:hover{
        transform:translate(-50%,-30px);
          visibility:visible;
          opacity:1;
      }
      &:after{
        content:'';
        position:absolute;
        width: 0; 
        height: 0; 
        bottom: -15px;
        left: calc(50% - 15px);
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #333;
      }
    }
    
  }
  
  
  
  #auto {
    /* background:$secondaryColor; */
    .current{
      font-size:10px;
      margin-left:3px;
      &:before{
        margin-left:-3px;
        content:'(';
        position:absolute;
      }
      &:after{
        content:')';
        position:absolute;
      }
      
    }
  }
  
  .item {
    position: relative;
    text-align: center;
    border: 0;
    padding: 10px;
    /* background: $primaryColor; */
  
    &.selected {
      /* background: $secondaryColor; */
      font-weight: bold;
      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        top: 50%;
        border-radius: 50%;
        background: #4caf50;
        left: 10px;
        transform: translateY(-50%);
      }
    }
  
    &:hover {
      /* background: $secondaryColor; */
      cursor: pointer;
      
    }
  }
  