.moviesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 5px;
    padding: 5px;
    justify-content: center;
  }

  @media (max-width: 560px) {
    .moviesGrid {
      grid-template-columns: 100%;
    }
  }